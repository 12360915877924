/**
 * Auth0 User Creation
 * Create the user in the Auth0 Database
 */
import { navigate } from "gatsby"

async function createUserRecord(params) {
  const response = await fetch("/.netlify/functions/create-user", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(params),
  })

  if (response.status === 401) {
    navigate("/logout")
  } else {
    return await response.json()
  }
}

export default createUserRecord
